<template>
  <div>
    <div class="banner">
      <div class="banner-content">
        <div class="banner-title">
          <h2>售后管理系统</h2>
          <p> 客户管理、工单管理、备件库存、<br>
            服务结算、设备资产、人员外勤一体化管理</p>
        </div>
        <div class="banner-img">
          <img src="../../../public/shgl_banner.png" alt="" style="width: 300px;margin-top: 40px">
        </div>
      </div>
    </div>
    <div class="nav">
      <el-menu  class="el-menu-demo" mode="horizontal"
               active-text-color="#e62129" router>
        <el-menu-item index="#1" style="" class="nav-item">
          <img src="../../../public/shgl_h1.png" alt="">
          售后管理
        </el-menu-item>
        <el-menu-item index="#2" style="" class="nav-item">
          <img src="../../../public/shgl_h2.png" alt="">
          备件管理
        </el-menu-item>
        <el-menu-item index="#3" style="" class="nav-item">
          <img src="../../../public/shgl_h3.png" alt="">
          用户管理
        </el-menu-item>
        <el-menu-item index="#4" style="" class="nav-item">
          <img src="../../../public/shgl_h4.png" alt="">
          客户管理
        </el-menu-item>
        <el-menu-item index="#5" style="" class="nav-item">
          <img src="../../../public/shgl_h5.png" alt="">
          产品管理
        </el-menu-item>
        <el-menu-item index="#6" style="" class="nav-item">
          <img src="../../../public/shgl_h6.png" alt="">
          统计分析
        </el-menu-item>
      </el-menu>
    </div>
    <div class="shg1">
      <div class="shg1-left">
        <div class="left-title">
          立体化报修渠道
        </div>
        <div class="left-content">
          平台支持电话、网站、微信、小程序、二维码、APP等报修渠道，与您的客户无缝连接。来自不同报修渠道的客户请求，系统生成工单集中统一处理。
        </div>
        <div class="left-option">
          <div class="option-item">
            <img src="../../../public/shgl_sect1_icon1.png" alt="">
            维修工报修
          </div>
          <div class="option-item">
            <img src="../../../public/shgl_sect1_icon2.png" alt="">
            客服报修
          </div>
          <div class="option-item">
            <img src="../../../public/shgl_sect1_icon3.png" alt="">
            自助报修
          </div>
        </div>
      </div>
      <div class="shg1-right">
        <img src="../../../public/shgl_sect1_img.png" alt="" width="200">
      </div>
    </div>
    <div class="shg2">
      <div class="shg2-right">
        <img src="../../../public/shgl_sect2_img.png" alt="" width="300">
      </div>
      <div class="shg2-left">
        <div class="left-title">
          智能化服务派工
        </div>
        <div class="left-content">
          您可将工单派给第三方服务商、或直接指派给服务人员。系统支持自动派工、手动派工，您可按所属区域、工单类型、产品类型等设置自动派工规则，工单流转无需人工干预，降低人力成本。
        </div>
        <div class="left-option">
          <div class="option-item">
            <img src="../../../public/shgl_sect1_icon1.png" alt="">
            手动派工
          </div>
          <div class="option-item">
            <img src="../../../public/shgl_sect1_icon2.png" alt="">
            自动派工
          </div>
          <div class="option-item">
            <img src="../../../public/shgl_sect1_icon3.png" alt="">
            重新派工
          </div>
        </div>
      </div>

    </div>
    <div class="shg3">
      <div class="shg3-title">
        自定义服务流程
        <p>系统支持上门服务、到店服务、返修服务、电话支持等服务 <br>
          模式，每种服务模式可以自定义不同的工单处理流程。
        </p>
      </div>
      <div class="shg3-content">
        <div class="item-line"></div>
        <div class="item">
          <div class="shg3-item">
            <img src="../../../public/shgl_sect3_t1.png" alt="">
            <div class="item-title">
              创建工单
            </div>
          </div>
          <div class="shg3-item">
            <img src="../../../public/shgl_sect3_t2.png" alt="">
            <div class="item-title">
              服务派工
            </div>
          </div>
          <div class="shg3-item">
            <img src="../../../public/shgl_sect3_t3.png" alt="">
            <div class="item-title">
              创建工单
            </div>
          </div>
          <div class="shg3-item">
            <img src="../../../public/shgl_sect3_t4.png" alt="">
            <div class="item-title">
              客户预约
            </div>
          </div>
          <div class="shg3-item">
            <img src="../../../public/shgl_sect3_t5.png" alt="">
            <div class="item-title">
              填写回单
            </div>
          </div>
          <div class="shg3-item">
            <img src="../../../public/shgl_sect3_t6.png" alt="">
            <div class="item-title">
              服务结算
            </div>
          </div>
          <div class="shg3-item">
            <img src="../../../public/shgl_sect3_t7.png" alt="">
            <div class="item-title">
              客户回访
            </div>
          </div>
          <div class="shg3-item">
            <img src="../../../public/shgl_sect3_t8.png" alt="">
            <div class="item-title">
              关闭订单
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="shg4">
      <div class="shg4-right">
        <img src="../../../public/shgl_sect4_img.png" alt="" width="300">
      </div>
      <div class="shg4-left">
        <div class="left-title">
          自定义工单模板
        </div>
        <div class="left-content">
          您可根据业务需要创建维修、安装、送货、巡检、保养等多种工单类型，每种工单类型可自定义工单录入模板。
        </div>
        <div class="left-option">
          <div class="option-item">
            <img src="../../../public/shgl_sect1_icon1.png" alt="">
            自定义模板
          </div>
          <div class="option-item">
            <img src="../../../public/shgl_sect1_icon2.png" alt="">
            自定义字段
          </div>
        </div>
      </div>

    </div>
    <div class="shg5">
      <div class="shg5-left">
        <div class="left-title">
          客户满意度评价
        </div>
        <div class="left-content">
          客户可通过微信、网站等报修渠道进行满意度评价，您也可通过客服人员回访客户获得客户满意度数据。
        </div>
        <div class="left-option">
          <div class="option-item">
            <img src="../../../public/shgl_sect1_icon1.png" alt="">
            客户评价
          </div>
          <div class="option-item">
            <img src="../../../public/shgl_sect1_icon2.png" alt="">
            客服回访
          </div>
          <div class="option-item">
            <img src="../../../public/shgl_sect1_icon3.png" alt="">
            自助报修
          </div>
        </div>
      </div>
      <div class="shg5-right">
        <img src="../../../public/shgl_sect5_img.png" alt="" width="200">
      </div>
    </div>
    <div class="shg6">
      <div class="shg6-right">
        <img src="../../../public/shgl_sect6_img.png" alt="" width="300">
      </div>
      <div class="shg6-left">
        <div class="left-title">
          服务时效性控制
        </div>
        <div class="left-content">
          您可设置工单的处理时限，如果工单处理延迟，系统将自动记录。您可获取服务效率统计报告，为您考核服务商、服务人员提供数据。
        </div>
        <div class="left-option">
          <div class="option-item">
            <img src="../../../public/shgl_sect1_icon1.png" alt="">
            网点统计
          </div>
          <div class="option-item">
            <img src="../../../public/shgl_sect1_icon2.png" alt="">
            人员统计
          </div>
          <div class="option-item">
            <img src="../../../public/shgl_sect6_icon1.png" alt="">
            时效规则
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "mobileProduct",
  mounted() {
    console.log("Product", Boolean(this._isMobile()));
    if (this._isMobile()) {
      // 为true是移动端,跳至移动端页面
      console.log("yi移动端");
      this.$router.replace('/mobileProduct')
    } else {
      // 为false是pc端，跳至pc端页面
      console.log("PC端");
      this.$router.replace('/product')
    }
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    // 判断是什么设备
    _isMobile() {
      let plat = navigator.userAgent.match( // 判断不同端
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return plat;
    }
  },
}
</script>

<style lang="scss" scoped>
.banner {
  height: 500px;
  background: #254390;
  color: #fff;
  text-align: left;

  .banner-content {
    text-align: center;

    .banner-title {
      h2 {
        font-size: 25px;
        color: #fff;
        line-height: 56px;
      }

      p {
        font-size: 15px;
        color: #fff;
        line-height: 30px;
        margin-top: 21px;
        font-family: 'pingfang';
      }
    }
  }
}

.nav {
  display: flex;
  justify-content: center;

  .nav-item {
    width: 119px;
  }
}

.shg1 {
  padding: 0 10px;
  .shg1-left {
    text-align: left;

    .left-title {
      margin-top: 20px;
      font-size: 20px;
      color: #000000;
      margin-bottom: 40px;
    }

    .left-content {
      font-size: 16px;
      color: #333333;
      line-height: 36px;

    }

    .left-option {
      display: flex;
      align-items: center;
      line-height: 30px;
      padding: 30px 0;
      font-size: 14px;
      .option-item {
        width: 49%;
        line-height: 50px
      }
    }
  }

}

.shg2 {
  background: #f8fafb;
  padding: 0 10px;
  .shg2-left {
    text-align: left;

    .left-title {
      font-size: 20px;
      color: #000000;
      margin-bottom: 40px;
    }

    .left-content {
      font-size: 12px;
      color: #333333;
      line-height: 36px;

    }

    .left-option {
      display: flex;
      text-align: center;
      align-items: center;
      line-height: 30px;
      .option-item {
        width: 49%;
        line-height: 50px
      }
    }
  }

}

.shg3 {
  height: 327px;

  .shg3-title {
    font-size: 20px;
    color: #000000;
    line-height: 32px;
    text-align: center;
    margin-top: 20px;

    p {
      font-size: 16px;
      color: #333333;
      text-align: left;
      line-height: 35px;
      margin: 20px auto 0;
    }
  }

  .shg3-content {
    width: 100%;
    margin: 0 auto;
    padding-top: 50px;
    white-space: nowrap;
    overflow-x: scroll;
    padding: 20px 0;

    .item-line {
      width: 93%;
      height: 0;
      left: 0;
      border-top: 1px dashed #f5b4b6;
      position: relative;
      top: 50px;
      margin: 0 auto;
    }

    .item {
      display: flex;
      justify-content: center;
      position: relative;
      z-index: 2;

      .shg3-item {

        margin-right: 80px;

        .item-title {
          font-size: 16px;
        }
      }
    }
  }
}

.shg4 {
  background: #f8fafb;
  padding: 0 10px;
  .shg4-left {
    .left-title {
      font-size: 20px;
      color: #000000;
      margin-bottom: 40px;
    }

    .left-content {
      font-size: 16px;
      color: #333333;
      line-height: 36px;
    }

    .left-option {
      display: flex;
      line-height: 30px;
      margin: 0 auto;
      margin-bottom: 20px;

      .option-item {
        width: 49%;
        line-height: 50px
      }
    }
  }

}

.shg5 {
  padding: 0 10px;
  .shg5-left {
    text-align: left;

    .left-title {
      font-size: 20px;
      color: #000000;
      margin-bottom: 40px;
    }

    .left-content {
      font-size: 16px;
      color: #333333;
      line-height: 36px;

    }

    .left-option {
      display: flex;
      align-items: center;
      line-height: 30px;
      padding: 30px 0;
      font-size: 14px;
      .option-item {
        width: 49%;
        line-height: 50px
      }
    }
  }

}

.shg6 {
  background: #f8fafb;
  padding: 0 10px;
  .shg6-left {
    .left-title {
      font-size: 20px;
      color: #000000;
      margin-bottom: 40px;
    }

    .left-content {
      font-size: 16px;
      color: #333333;
      line-height: 36px;
    }

    .left-option {
      display: flex;
      align-items: center;
      line-height: 30px;
      margin: 0 auto;
    font-size: 14px;
      .option-item {
        width: 49%;
        line-height: 50px
      }
    }
  }

}
</style>